import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import CircularProgress from '@mui/material/CircularProgress';
import { useQuery } from '@apollo/client';
import FingoDialog from '@fingo/lib/components/dialogs/FingoDialog';
import WalletIcon from '@mui/icons-material/Wallet';
import TextField from '@mui/material/TextField';
import SearchIcon from '@mui/icons-material/Search';
import InputAdornment from '@mui/material/InputAdornment';
import useDebounce from '@fingo/lib/hooks/useDebounce';
import { CREDIT_LINES } from '../../graphql/credit-lines/queries';
import SublinesCard from './SublineCard';

const styles = {
  dialog: {
    '& .MuiDialog-paper': {
      minHeight: '800px',
      minWidth: '1200px',
    },
    '& .MuiDialogContent-root': {
      height: '100%',
    },
  },
  contentWrapper: {
    px: 3,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  searchContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    mb: 5,
  },
  searchBox: {
    width: '40%',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    py: 20,
    width: '100%',
  },
  emptyStateContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
};

const EntityHeader = ({ entityName }) => (
  <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
    <WalletIcon sx={{ fontSize: 28, color: 'text.primary' }} />
    <Typography variant="h6" color="text.primary" sx={{ fontWeight: 'bold' }}>
      {entityName}
    </Typography>
  </Box>
);

const EmptyState = () => (
  <Box sx={styles.emptyStateContainer}>
    <CreditCardOffIcon sx={{ fontSize: 64, color: 'grey.400', mb: 2, mt: 20 }} />
    <Typography variant="h6" color="text.secondary" align="center">
      Esta empresa no tiene sublíneas configuradas
    </Typography>
  </Box>
);

const LoadingState = () => (
  <Box sx={styles.loadingContainer}>
    <CircularProgress />
  </Box>
);

const SublinesDialog = ({ open, onClose, masterEntity }) => {
  const [searchQuery, setSearchQuery] = useState('');
  const [debouncedQuery, setDebouncedQuery] = useState('');

  const debouncedSetQuery = useDebounce((value) => {
    setDebouncedQuery(value);
  }, 500);

  const handleSearchChange = (e) => {
    const { value } = e.target;
    setSearchQuery(value);
    debouncedSetQuery(value);
  };

  const { data, loading } = useQuery(CREDIT_LINES, {
    variables: {
      masterEntity_Id: masterEntity.graphqlId,
      creditlineType: 'SUBLINE',
      debtorName: debouncedQuery,
    },
    skip: !open,
    fetchPolicy: 'network-only',
  });

  return (
    <FingoDialog open={open} handleClose={onClose} title="Empresas y sublíneas" maxWidth="lg" sx={styles.dialog}>
      <Box sx={styles.contentWrapper}>
        {/* Header + Search Bar */}
        <Box sx={styles.searchContainer}>
          <EntityHeader entityName={masterEntity.name} />
          <Box sx={styles.searchBox}>
            <TextField
              fullWidth
              placeholder="Buscar por nombre del deudor..."
              value={searchQuery}
              onChange={handleSearchChange}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              size="small"
            />
          </Box>
        </Box>

        {/* Conditional Rendering */}
        {loading && <LoadingState />}
        {!loading && data?.creditLines?.edges?.length === 0 && <EmptyState />}
        {!loading && data?.creditLines?.edges?.length > 0 && (
          <Grid container spacing={3}>
            {data.creditLines.edges.map(({ node }) => (
              <Grid item xs={12} md={6} key={node.id}>
                <SublinesCard creditLine={node} isEditable />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </FingoDialog>
  );
};

SublinesDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  masterEntity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    graphqlId: PropTypes.string.isRequired,
  }).isRequired,
};

EntityHeader.propTypes = {
  entityName: PropTypes.string.isRequired,
};

export default SublinesDialog;
