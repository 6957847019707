import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import SublinesIconButton from './SublinesIconButton';
import SublineDetailsDialog from './SublineDetailsDialog';

const SublineCell = ({ creditLine }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Box>
      <SublinesIconButton
        onClick={handleOpenDialog}
        disabled={!creditLine}
      />

      <SublineDetailsDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        entityName={creditLine?.masterEntity?.name}
        creditLine={creditLine}
      />
    </Box>
  );
};

SublineCell.propTypes = {
  creditLine: PropTypes.shape({
    id: PropTypes.string.isRequired,
    masterEntity: PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default SublineCell;
