import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import SublinesIconButton from './SublinesIconButton';
import SublinesDialog from './SublinesDialog';

const SublinesCell = ({ masterEntity }) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  const handleOpenDialog = () => {
    setIsDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setIsDialogOpen(false);
  };

  return (
    <Box>
      <SublinesIconButton
        onClick={handleOpenDialog}
        disabled={!masterEntity.hasSubline}
      />

      <SublinesDialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        masterEntity={masterEntity}
      />
    </Box>
  );
};

SublinesCell.propTypes = {
  masterEntity: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    hasSubline: PropTypes.bool.isRequired,
  }).isRequired,
};

export default SublinesCell;
