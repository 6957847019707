import { useQuery } from '@apollo/client';
import { FingoDataGrid } from '@fingo/lib/components/dataGrids';
import FingoTabs from '@fingo/lib/components/tabs/FingoTabs';
import Box from '@mui/material/Box';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { CREDIT_LINE_EVALUATION_REQUESTS_BY_MASTERENTITY } from '@fingo/lib/graphql';
import { HISTORY_COLUMNS } from '../../constants/credit-line-evaluation-requests';

const HistoryList = ({ masterEntityId, currency }) => {
  const numberOfPastEvaluations = 10;
  const { data, loading } = useQuery(CREDIT_LINE_EVALUATION_REQUESTS_BY_MASTERENTITY, {
    variables: {
      masterEntityId,
      currency,
    },
  });
  const rows = useMemo(() => {
    if (!data) return [];
    const creditLine = data.getMasterEntity?.creditLine;
    return creditLine?.creditLineEvaluationRequestsAll
      .map((req) => ({
        id: req.id,
        requestDate: req.requestDate,
        status: req.status,
        approvedAmount: req.approvedAmount,
        comment: req.comment,
      }))
      .sort((a, b) => new Date(b.requestDate) - new Date(a.requestDate));
  }, [data]);

  const includeHeaders = [
    'requestDate',
    'status',
    'approvedAmount',
    'comment',
  ];
  return (
    <Box height="100%" width="100%">
      <FingoDataGrid
        rows={rows}
        includeHeaders={includeHeaders}
        columns={HISTORY_COLUMNS}
        loadingWithSkeleton={loading}
        serverFilters={false}
        rowCount={numberOfPastEvaluations}
        page={0}
        pageSize={numberOfPastEvaluations}
        hideFooter
      />
    </Box>
  );
};

HistoryList.propTypes = {
  masterEntityId: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
};

const CreditLinePastEvaluationComponent = ({ masterEntityId, currency }) => (
  <FingoTabs
    tabInfo={[
      {
        tabName: 'Cliente',
        component: HistoryList,
        componentProps: { masterEntityId, currency },
      },
    ]}
  />
);

CreditLinePastEvaluationComponent.propTypes = {
  masterEntityId: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
};

export default CreditLinePastEvaluationComponent;
