import React, { useState } from 'react';
import propTypes from 'prop-types';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import LoadingButton from '@mui/lab/LoadingButton';
import { ValidationTextFieldInput } from '@fingo/lib/components/inputs';
import { useMutation } from '@apollo/client';
import { useSnackBars, useGetCountryFromUrl } from '@fingo/lib/hooks';
import CreditScore from '@mui/icons-material/CreditScore';
import AutoCompleteMasterEntity from '@fingo/lib/components/inputs/AutoCompleteMasterEntity';
import CurrencyFilter from '@fingo/lib/components/filters/CurrencyFilter';
import { CREDIT_LINE_MASTER_ENTITIES } from '@fingo/lib/graphql/customers/queries';
import { CREATE_CREDIT_LINE } from '../../graphql/credit-lines/mutations';

const CreateCreditLineDialog = ({ open, handleClose }) => {
  const country = useGetCountryFromUrl();
  const { addAlert } = useSnackBars();
  const [selectedEntity, setSelectedEntity] = useState(null);
  const [selectedCurrency, setSelectedCurrency] = useState(country.currencies[0].isoCode);
  const [newLimit, setNewLimit] = useState('');

  const [createCreditLine, { loading }] = useMutation(CREATE_CREDIT_LINE, {
    onCompleted: () => {
      addAlert({
        id: 'credit-line-created',
        message: 'Línea de crédito creada correctamente',
        color: 'success',
        severity: 'success',
      });
      handleClose();
    },
    onError: (e) => {
      addAlert({
        id: 'credit-line-error',
        message: e.message,
        severity: 'error',
        color: 'error',
      });
    },
    refetchQueries: [CREDIT_LINE_MASTER_ENTITIES],
  });

  const handleCreateCreditLine = () => {
    createCreditLine({
      variables: {
        masterEntityId: selectedEntity.id,
        currency: selectedCurrency,
        limit: parseFloat(newLimit),
      },
    });
  };

  const buttons = [
    {
      key: 'cancelButton',
      label: 'Cancelar',
      variant: 'outlined',
      onClick: handleClose,
    },
    {
      key: 'saveButton',
      label: 'Crear',
      variant: 'contained',
      onClick: handleCreateCreditLine,
    },
  ];

  return (
    <FingoDialog
      open={open}
      handleClose={handleClose}
      icon={CreditScore}
      title="Crear línea de crédito"
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: '450px',
        },
      }}
    >
      <Stack direction="column" spacing={3}>
        <AutoCompleteMasterEntity
          setEntity={setSelectedEntity}
          label="Selecciona una empresa"
        />
        <Typography variant="h6" fontWeight={700}>Monto de la línea:</Typography>
        <Stack direction="row" spacing={2} alignItems="center">
          <CurrencyFilter
            currency={selectedCurrency}
            setCurrency={setSelectedCurrency}
          />
          <ValidationTextFieldInput
            validationtype="text"
            placeholder="Ingresa el monto"
            name="newCreditLineLimit"
            type="number"
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  {country.currencies.find((cur) => cur.isoCode === selectedCurrency)?.symbol}
                </InputAdornment>
              ),
            }}
            value={newLimit}
            onChange={(e) => setNewLimit(e.target.value)}
          />
        </Stack>
        <Stack direction="row" spacing={2} justifyContent="center">
          {buttons.map(({ key, label, variant, onClick }) => (
            <LoadingButton key={key} variant={variant} onClick={onClick} size="small" loading={loading}>
              {label}
            </LoadingButton>
          ))}
        </Stack>
      </Stack>
    </FingoDialog>
  );
};

CreateCreditLineDialog.propTypes = {
  open: propTypes.bool.isRequired,
  handleClose: propTypes.func.isRequired,
};

export default CreateCreditLineDialog;
