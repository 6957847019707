import React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import CreditLineCard from './SublineCard';

const SublineDetailsDialog = ({ open, onClose, entityName, creditLine }) => (
  <FingoDialog
    open={open}
    handleClose={onClose}
    title={entityName}
  >
    <Box py={2}>
      <CreditLineCard creditLine={creditLine} />
    </Box>
  </FingoDialog>
);

SublineDetailsDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  entityName: PropTypes.string.isRequired,
  creditLine: PropTypes.shape({
    id: PropTypes.string.isRequired,
  }).isRequired,
};

export default SublineDetailsDialog;
