import React, { useState } from 'react';
import propTypes from 'prop-types';
import { FingoDialog } from '@fingo/lib/components/dialogs';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import AddCardIcon from '@mui/icons-material/AddCard';
import { useSnackBars } from '@fingo/lib/hooks';
import Box from '@mui/material/Box';
import InfoIcon from '@mui/icons-material/Info';
import Tooltip from '@mui/material/Tooltip';
import { useMutation } from '@apollo/client';
import RequiredFieldsList from '../../../../components/tooltips/RequiredFieldsList';
import FileUploadBox from '../../../../components/buttons/FileUploadBox';
import { CREATE_SUBLINES_FROM_FILE } from '../../graphql/credit-lines/mutations';

const REQUIRED_FIELDS = [
  'company_id',
  'receiver_id',
  'amount_with_iva',
  'amount_with_iva_currency',
];

const UploadSublinesDialog = ({ open, handleClose }) => {
  const { addAlert } = useSnackBars();
  const [selectedFile, setSelectedFile] = useState(null);

  const [createSublinesFromFile, { loading }] = useMutation(CREATE_SUBLINES_FROM_FILE, {
    onCompleted: (data) => {
      addAlert({
        id: 'sublines-uploaded',
        message: data.createSublinesFromFile.message,
        color: data.createSublinesFromFile.success ? 'success' : 'warning',
        severity: data.createSublinesFromFile.success ? 'success' : 'warning',
      });
      handleClose();
    },
    onError: (e) => {
      addAlert({
        id: 'sublines-upload-error',
        message: e.message,
        color: 'error',
        severity: 'error',
      });
    },
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
  };

  const handleUpload = () => {
    createSublinesFromFile({
      variables: { file: selectedFile },
    });
  };

  return (
    <FingoDialog
      open={open}
      handleClose={handleClose}
      icon={AddCardIcon}
      title="Crear o actualizar sublíneas"
      sx={{
        '& .MuiDialog-paper': {
          width: '100%',
          maxWidth: '450px',
        },
      }}
    >
      <Stack direction="column" spacing={3}>
        <Box>
          <Typography
            variant="h6"
            gutterBottom
            display="flex"
            alignItems="center"
            gap={1}
          >
            Sube un archivo con sublíneas
            <Tooltip
              title={(
                <RequiredFieldsList
                  title="El archivo debe incluir:"
                  fields={REQUIRED_FIELDS}
                />
              )}
              arrow
              componentsProps={{
                tooltip: {
                  sx: {
                    bgcolor: 'grey.800',
                  },
                },
              }}
            >
              <InfoIcon
                fontSize="small"
                sx={{ cursor: 'pointer' }}
              />
            </Tooltip>
          </Typography>
        </Box>

        <FileUploadBox
          selectedFile={selectedFile}
          onFileChange={handleFileChange}
        />

        <Stack direction="row" spacing={2} justifyContent="center">
          <LoadingButton
            variant="contained"
            onClick={handleUpload}
            size="small"
            loading={loading}
            disabled={!selectedFile}
          >
            Subir Archivo
          </LoadingButton>
        </Stack>
      </Stack>
    </FingoDialog>
  );
};

UploadSublinesDialog.propTypes = {
  open: propTypes.bool.isRequired,
  handleClose: propTypes.func.isRequired,
};

export default UploadSublinesDialog;
