import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import CreditCard from '@mui/icons-material/CreditCard';
import AddCardIcon from '@mui/icons-material/AddCard';
import CreateCreditLineDialog from './CreateCreditLine';
import UploadSublinesDialog from './UploadSublines';

const useDialog = () => {
  const [isOpen, setIsOpen] = useState(false);

  const open = () => setIsOpen(true);
  const close = () => setIsOpen(false);

  return { isOpen, open, close };
};

const CreditLineActions = () => {
  const creditLineDialog = useDialog();
  const sublinesDialog = useDialog();

  return (
    <Stack direction="row" spacing={2}>
      <Button
        variant="contained"
        size="small"
        color="primary"
        startIcon={<CreditCard />}
        onClick={creditLineDialog.open}
      >
        Crear línea de crédito
      </Button>
      <Button
        variant="contained"
        size="small"
        color="primary"
        startIcon={<AddCardIcon />}
        onClick={sublinesDialog.open}
      >
        Subir sublineas
      </Button>

      {creditLineDialog.isOpen && (
        <CreateCreditLineDialog
          open={creditLineDialog.isOpen}
          handleClose={creditLineDialog.close}
        />
      )}
      {sublinesDialog.isOpen && (
        <UploadSublinesDialog
          open={sublinesDialog.isOpen}
          handleClose={sublinesDialog.close}
        />
      )}
    </Stack>
  );
};

export default CreditLineActions;
