import React from 'react';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import BlurOnIcon from '@mui/icons-material/BlurOn';
import BlurOffIcon from '@mui/icons-material/BlurOff';

const SublinesIconButton = ({
  onClick,
  disabled,
  size,
  color,
}) => (
  <Tooltip title={disabled ? 'Sin sublíneas' : 'Ver sublíneas'}>
    <Box component="span">
      <IconButton
        onClick={onClick}
        size={size}
        color={color}
        disabled={disabled}
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          border: `1px solid ${theme.palette.primary.main}`,
          '&.Mui-disabled': {
            backgroundColor: theme.palette.action.disabledBackground,
            borderColor: theme.palette.action.disabled,
          },
        })}
      >
        {disabled ? <BlurOffIcon /> : <BlurOnIcon />}
      </IconButton>
    </Box>
  </Tooltip>
);

SublinesIconButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
  color: PropTypes.string,
};

SublinesIconButton.defaultProps = {
  disabled: false,
  size: 'small',
  color: 'primary',
};

export default SublinesIconButton;
