import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { EditCreditLineButton } from '@fingo/lib/components/buttons';
import CreditLineLinearProgress from '@fingo/lib/components/progress/CreditLineLinearProgress';
import { CREDIT_LINES } from '../../graphql/credit-lines/queries';

const SublineCard = ({ creditLine, isEditable }) => {
  const { debtor, lastUpdate } = creditLine;

  return (
    <Card
      sx={{
        display: 'flex',
        width: '500px',
        height: '150px',
        padding: '8px 12px 24px 12px',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        alignContent: 'flex-start',
        rowGap: '16px',
        flexShrink: 0,
        flexWrap: 'wrap',
        borderRadius: '12px',
        bgcolor: 'background.paper',
        boxShadow: 2,
        '& .MuiCardContent-root': {
          padding: 0,
          width: '100%',
        },
      }}
    >
      <CardContent>
        <Stack spacing={3}>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Box>
              <Typography variant="h6" component="h3" sx={{ mb: 0.5 }}>
                {debtor.name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                Última actualización: {dayjs(lastUpdate).format('DD/MM/YYYY')}
              </Typography>
            </Box>
            {isEditable && (
              <EditCreditLineButton
                creditLine={creditLine}
                masterEntityName={debtor.name}
                query={CREDIT_LINES}
              />
            )}
          </Box>

          <CreditLineLinearProgress creditLine={creditLine} width="100%" />
        </Stack>
      </CardContent>
    </Card>
  );
};

SublineCard.propTypes = {
  creditLine: PropTypes.shape({
    debtor: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    lastUpdate: PropTypes.string.isRequired,
  }).isRequired,
  isEditable: PropTypes.bool,
};

SublineCard.defaultProps = {
  isEditable: false,
};

export default SublineCard;
