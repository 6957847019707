import React from 'react';
import FingoDataGrid from '@fingo/lib/components/dataGrids/FingoDataGrid';
import PaperHeader from '@fingo/lib/components/headers/PaperHeader';
import FingoMainView from '@fingo/lib/components/layout/FingoMainView';
import useGetCountryFromUrl from '@fingo/lib/hooks/useGetCountryFromUrl';
import Typography from '@mui/material/Typography';
import { CREDIT_LINE_MASTER_ENTITIES } from '@fingo/lib/graphql/customers/queries';
import { HEADER_TITLE, COLUMNS } from '../../constants/credit-line-manager';
import CreditLineActions from './CreditLineActions';

const CreditLines = () => {
  const country = useGetCountryFromUrl();

  return (
    <FingoMainView
      id="credit-line-manager"
      query={CREDIT_LINE_MASTER_ENTITIES}
      queryCustomVariables={{
        countryId: country?.id,
      }}
      slots={{
        header: PaperHeader,
        table: FingoDataGrid,
        actions: CreditLineActions,
      }}
      slotProps={{
        header: {
          viewTitle: HEADER_TITLE,
          finder: {
            searchPlaceHolder: 'Buscar empresa',
          },
        },
        table: {
          columns: COLUMNS,
          rowHeight: 110,
          includeHeaders: ['name', 'creditLines', 'sublines'],
          rowsPerPageOptions: [10, 25, 50, 75, 100],
          checkboxSelection: false,
          noRowsMessage: () => (
            <Typography variant="body2">No hay líneas de crédito</Typography>
          ),
          initialOrderBy: 'id',
        },
      }}
    />
  );
};

export default CreditLines;
